<template>
    <div v-if="showModal" class="modal show" style="display: block; background-color: rgba(0,0,0,0.8);" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="d-flex justify-content-between">
                        <ShareBuildingIcon class="mt-3 ms-3" />
                        <button type="button" class="btn-close p-3" data-bs-dismiss="modal" aria-label="Close"
                            @click="closeModal"></button>
                    </div>
                    <div class="row py-2">
                        <p class="title mt-1 mb-1">Share property</p>
                        <p class="subtitle mt-1 mb-1">Share your property with people </p>
                    </div>
                    <div class="row">
                        <div class="col-10">
                            <ShareEmailInput ref="ShareEmailInput" label="Email address" @input="handleInput" />
                        </div>
                        <div class="col-2 pt-4">
                            <BlueBtn msg="Invite" :disabled="formData.email == ''" type="button" @click="invite" />
                        </div>
                    </div>

                    <hr v-if="sharedWith.length > 0">
                    <p class="shared-text" v-if="sharedWith.length > 0">This property is currently shared with the
                        following accounts</p>
                    <div class="row ps-3 pe-0" v-for="(shared, index) in sharedWith" :key="index">
                        <div class="col-1 p-0 m-1"><img :src="shared.client.image" class="img-fluid rounded-circle"
                                width="40" height="40"></div>
                        <div class="col-8 p-0 m-1">
                            <p class="name p-0 m-0">{{ shared.client.first_name }} {{ shared.client.last_name }}</p>
                            <p class="email p-0 m-0">{{ shared.client.email }}</p>
                        </div>
                        <div class="col-2 p-0 m-1 text-end">
                            <div class="dropdown">
                                <button style="text-decoration: none !important;"
                                    class="btn btn-link dropdown-toggle input-group-text p-2 rounded-0 rounded-end bg-white border-0"
                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <span v-if="shared.is_viewer">View</span>
                                    <span v-if="shared.is_editor">Edit</span>
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><button class="dropdown-item" @click="view(shared.id)">View Access</button></li>
                                    <li><button class="dropdown-item" @click="edit(shared.id)">Edit Access</button></li>
                                    <li><button class="dropdown-item" @click="remove(shared.id)">Remove</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { eventBus } from '@/events/eventBus.js';
import BlueBtn from '@/components/buttons/BlueBtn.vue';
import ShareBuildingIcon from '@/components/icons/ShareBuildingIcon.vue';
import ShareEmailInput from '@/components/inputs/ShareEmailInput.vue';
import { ApiService } from '@/Services/ApiService';

export default {
    name: 'SharePropertyModal',
    components: { BlueBtn, ShareBuildingIcon, ShareEmailInput },
    data() {
        return {
            api: new ApiService(),
            showModal: false,
            propertyId: 0,
            sharedWith: [
                {
                    "id": 0,
                    "property_id": 0,
                    "client_id": 0,
                    "is_viewer": true,
                    "is_editor": true,
                    "client": {
                        "first_name": "",
                        "last_name": "",
                        "email": "",
                        "phone": "",
                        "image": "",
                        "created_at": "",
                        "updated_at": "",
                        "country": {
                            "id": 0,
                            "name": "",
                            "country_code": "",
                            "landline_format": "",
                            "landline_format_hint": "",
                            "mobile_format": "",
                            "mobile_format_hint": "",
                            "currency": "",
                            "flag": "",
                            "created_at": "",
                            "updated_at": ""
                        }
                    }
                }
            ],
            formData: {
                email: '',
                is_viewer: false,
                is_editor: false,
            },
        };
    },
    created() {
        eventBus.on('open-share-property-modal', (property_id) => {
            this.openModal(property_id)
        });
        this.sharedWith = []
    },
    methods: {
        async view(share_id) {
            eventBus.emit('show-loading', true);
            const response = await this.api.patch(`clients/properties/${this.propertyId}/share/${share_id}/is_viewer`);
            if ('errors' in response) {
                eventBus.emit('show-loading', false);
                eventBus.emit('open-error-modal', response.message);
            }
            else {
                eventBus.emit('show-loading', false);
                this.getSharedWith()
            }
        },
        async edit(share_id) {
            eventBus.emit('show-loading', true);
            const response = await this.api.patch(`clients/properties/${this.propertyId}/share/${share_id}/is_editor`);
            if ('errors' in response) {
                eventBus.emit('show-loading', false);
                eventBus.emit('open-error-modal', response.message);
            }
            else {
                eventBus.emit('show-loading', false);
                this.getSharedWith()
            }
        },
        async remove(share_id) {
            eventBus.emit('show-loading', true);
            const response = await this.api.delete(`clients/properties/${this.propertyId}/share/${share_id}`);
            if ('errors' in response) {
                eventBus.emit('show-loading', false);
                eventBus.emit('open-error-modal', response.message);
            }
            else {
                eventBus.emit('show-loading', false);
                this.getSharedWith()
            }
        },
        openModal(property_id) {
            this.propertyId = property_id;
            this.showModal = true;
            this.getSharedWith()
        },
        closeModal() {
            this.propertyId = 0;
            this.showModal = false;
            this.formData.email = ''
            this.formData.is_viewer = false
            this.formData.is_editor = false
        },
        handleInput(newVal) {
            this.formData.email = newVal.email
            this.formData.is_viewer = newVal.is_viewer
            this.formData.is_editor = newVal.is_editor
        },
        async getSharedWith() {
            eventBus.emit('show-loading', true);
            const response = await this.api.get(`clients/properties/${this.propertyId}/share`);
            if ('errors' in response) {
                eventBus.emit('open-error-modal', response.message);
                eventBus.emit('show-loading', false);
            }
            else {
                eventBus.emit('show-loading', false);
                this.sharedWith = response.data
            }
        },
        async invite() {
            try {
                eventBus.emit('show-loading', true);
                const response = await this.api.post(`clients/properties/${this.propertyId}/share`, this.formData);
                if ('errors' in response) {
                    eventBus.emit('open-error-modal', response.message);
                    eventBus.emit('show-loading', false);
                }
                else {
                    eventBus.emit('show-loading', false);
                    this.$refs.ShareEmailInput.clear()
                    this.getSharedWith()
                }
            }
            catch (err) {
                eventBus.emit('open-error-modal', err.message);
            }
        }
    },
    unmounted() {
        eventBus.off('open-share-property-modal', this.openModal);
        eventBus.off('close-share-property-modal', this.closeModal);
    },
};
</script>
<style scoped>
.name {
    color: var(--Gray-700, #344054);
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 20px !important;
}
.email {
    color: var(--Gray-600, #475467);
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}
.title {
    color: var(--Prmary-1, #2F4F5C);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.subtitle {
    color: var(--Prmary-1, #2F4F5C);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.shared-text {
    color: var(--Prmary-1, #2F4F5C) !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 112.5% !important;
}
</style>