<template>
    <label for="email" class="form-label mb-0" style="color: #2f4f5c"><b>{{ label }}</b></label>
    <div class="input-group">
        <span class="input-group-text bg-white border-end-0" :class="{ 'input-error': validate }">
            <EmailIcon />
        </span>
        <input type="text" v-model="email"
            placeholder="Name@email.com" 
            class="form-control p-2 border-start-0" 
            :class="{ 'input-error': validate }"
            required style="background-color: white" />
        <div class="dropdown">
            <button style="text-decoration: none !important;" class="btn btn-link dropdown-toggle input-group-text p-2 rounded-0 rounded-end bg-white border border-start-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <span v-if="is_viewer">View</span>
                <span v-if="is_editor">Edit</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><button class="dropdown-item" @click="view">View Access</button></li>
                <li><button class="dropdown-item" @click="edit">Edit Access</button></li>
            </ul>
        </div>
    </div>
</template>
<script>
import EmailIcon from "@/components/icons/EmailIcon.vue";
export default {
    name: 'ShareEmailInput',
    components: { EmailIcon },
    data() {
        return {
            email: '',
            is_viewer: true,
            is_editor: false,
        }
    },
    props: { validate: Boolean, label: { type: String, default: 'Email' } },
    computed: {
        isEmailFormat() {
            if (this.email !== "") {
                return !(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.email));
            }
            return false;
        },
    },
    emits: ['input'],
    watch:{
        email(){
            if(!this.isEmailFormat){
                this.$emit('input', { email: this.email, is_viewer: this.is_viewer, is_editor: this.is_editor, })
            }else{
                this.$emit('input', { email: '', is_viewer: this.is_viewer, is_editor: this.is_editor, })
            }
        },
        is_viewer(){
            if(!this.isEmailFormat){
                this.$emit('input', { email: this.email, is_viewer: this.is_viewer, is_editor: this.is_editor, })
            }else{
                this.$emit('input', { email: '', is_viewer: this.is_viewer, is_editor: this.is_editor, })
            }
        },
        is_editor(){
            if(!this.isEmailFormat){
                this.$emit('input', { email: this.email, is_viewer: this.is_viewer, is_editor: this.is_editor, })
            }else{
                this.$emit('input', { email: '', is_viewer: this.is_viewer, is_editor: this.is_editor, })
            }
        }
    },
    methods: {
        view(){
            this.is_viewer = true
            this.is_editor = false
        },
        edit(){
            this.is_viewer = false
            this.is_editor = true
        },
        clear(){
            this.email =  ''
            this.is_viewer =  true
            this.is_editor =  false
        }
    },
}
</script>
<style scoped>
    input{
        border-right: none !important;
        border-left: none !important;
    }
</style>