<template>
    <button :type="type" class="btn btn-outline-danger w-100 p-2">
        {{ msg }}
    </button>
</template>

<script>
export default {
    name: "DangerBtn",
    props: {
        type: { type: String, default: 'button' },
        msg: String,
    },
    components: {},
};
</script>
<style>
.btn-theme-secondary, .btn-theme-secondary:hover{
    border-radius: 12px;
    border: 1px solid #2F4F5C;
    background: #F9F9F9;
}
</style>
