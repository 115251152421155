<template>
    <div class="row justify-content-between flex-wrap flex-md-nowrap align-items-center py-3 px-5">
        <div class="col-6">
            <BreadCrumb :breadcrumbs="breadcrumbs" :firstName="firstName" />
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-10">
                    <SearchForm />
                </div>
                <div class="col-1">
                    <NotificationDropdown />
                </div>
                <div class="col-1">
                    <ProfileDropdown :profileImage="profileImage"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/layouts/partials/BreadCrumb.vue';
import NotificationDropdown from '@/components/layouts/partials/NotificationDropdown.vue';
import ProfileDropdown from '@/components/layouts/partials/ProfileDropdown.vue';
import SearchForm from '@/components/layouts/partials/SearchForm.vue';
export default {
    name: 'NavBar',
    props: { breadcrumbs: Array, profileImage: String, firstName: String },
    components: { BreadCrumb, SearchForm, NotificationDropdown, ProfileDropdown }
}
</script>