<template>
    <div class="dropdown m-0 mt-1 p-0">
        <a id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
            <BillIcon />
        </a>
        <ul class="dropdown-menu" aria-labelledby="dropdownUser2">
            <li v-if="notifications.length == 0" class="dropdown-item text-center">
                <p class="mb-0 no-notification-1">No notification yet!</p>
                <NotificationEmptyIcon />
                <p class="no-notification-2">Will notify you when something arrives!</p>
            </li>
            <li class="d-flex justify-content-center align-items-center px-2 py-1"
                v-for="(notification, index) in notifications" :key="index">
                <div class="dropdown-icon pe-2">
                    <NotificationVerifiedIcon v-if="notification.notification_type_id == 1" />
                    <NotificationShareIcon v-if="notification.notification_type_id==2" />
                </div>
                <div class="notification-text">
                    {{ notification.message }}
                    <br>
                    <span class="notification-time">{{ notification.created_at_for_humans }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { ApiService } from '@/Services/ApiService';
import BillIcon from '@/components/icons/BillIcon.vue';
import NotificationEmptyIcon from '@/components/icons/NotificationEmptyIcon.vue';
import NotificationShareIcon from '@/components/icons/NotificationShareIcon.vue';
import NotificationVerifiedIcon from '@/components/icons/NotificationVerifiedIcon.vue';

export default {
    name: 'NotificationDropdown',
    components: { BillIcon, NotificationEmptyIcon, NotificationShareIcon, NotificationVerifiedIcon },
    data() {
        return {
            api: new ApiService(),
            notifications: []
        };
    },
    async mounted() {
        const response = await this.api.get(`clients/notifications`);
        if ('errors' in response) {
            // 
        }
        else {
            this.notifications = response.data
        }
    },
}
</script>
<style scoped>
.no-notification-1 {
    color: var(--Prmary-1, #2F4F5C) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 150% !important;
    letter-spacing: 0.3px !important;
}
.no-notification-2 {
    color: var(--Prmary-1, #2F4F5C) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 150% !important;
    letter-spacing: 0.3px !important;
}
.notification-text {
    color: var(--Prmary-1, #2F4F5C) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0.3px !important;
}
.notification-time {
    color: var(--Grey, #AAA) !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 100% !important;
    letter-spacing: 0.3px !important;
}
</style>