<template>
    <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
        <div v-for="toast in toasts" :key="toast.id" class="toast align-items-center show" role="alert"
            aria-live="assertive" aria-atomic="true">
            <div class="d-flex">
                <div class="toast-body">
                    <SuccessIcon class="me-2" /> {{ toast.msg }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/events/eventBus.js';
import SuccessIcon from '@/components/icons/SuccessIcon.vue';

export default {
    data() {
        return {
            toasts: [],
            nextId: 0
        };
    },
    created() {
        eventBus.on('open-success-toast', (msg) => {
            this.showToast(msg);
        });
    },
    methods: {
        showToast(msg) {
            const id = this.nextId++;
            this.toasts.push({ id: id, msg: msg });
            setTimeout(() => {  this.removeToast(id) }, 3000);
        },
        removeToast(id) {
            this.toasts = this.toasts.filter(toast => toast.id !== id);
        }
    },
    unmounted() {
        eventBus.off('open-success-toast', this.showToast);
        eventBus.off('close-success-toast', this.removeToast);
    },
    components: { SuccessIcon }
};
</script>
<style scoped>
.toast {
    border-radius: 12px !important;
    border-left: 15px solid #4CB970;
    box-shadow: none;
}
</style>