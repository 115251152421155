<template>
    <GifLoading />
    <SuccessModal />
    <div class="container-fluid">
        <div class="row text-center min-vh-100 min-vw-100">
            <div class="col-lg-6 col-md-8 col-sm-12">
                <div class="row justify-content-center align-content-center">
                    <router-view @change-image="updateImage" />
                </div>
            </div>
            <div class="col-lg-6 col-md-4 col-sm-0 d-none d-md-inline" :style="SideImageStyle"></div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/events/eventBus';
import GifLoading from "@/components/loading/GifLoading.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";

export default {
    name: "AuthLayout",
    components: { GifLoading, SuccessModal },
    data() {
        return {
            SideImageStyle: {}
        }
    },
    methods: {
        updateImage(newImage) {
            this.SideImageStyle = newImage;
        }
    },
    mounted() {
        eventBus.emit('show-loading', false);
    },
};
</script>
