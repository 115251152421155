<template>
  <div
    v-if="showModal"
    class="modal show"
    style="display: block; background-color: rgba(0, 0, 0, 0.8)"
    tabindex="-1"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center position-relative">
          <button
            type="button"
            class="btn position-absolute top-0 end-0 p-3"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="decline"
          >
            <CloseIcon />
          </button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="215"
            height="194"
            viewBox="0 0 215 194"
            fill="none"
          >
            <path d="M25.5781 0H150.115V169.822H25.5781V0Z" fill="#2F4F5C" />
            <path
              d="M170.865 176.65H46.3281V29.7309L64.7402 6.82812H170.865V176.65Z"
              fill="#F7F7F7"
            />
            <path
              d="M68.3661 150.78L67.4141 148.876C67.45 148.858 69.9468 147.618 72.785 145.93C72.4078 145.463 72.0844 144.942 71.797 144.385C69.39 139.697 71.348 137.362 72.3 136.589C74.6352 134.667 79.0181 134.901 81.8563 137.128C82.6107 137.721 82.97 138.547 82.8802 139.427C82.7544 140.595 81.9281 142.553 76.3417 146.217C77.8146 147.079 79.4672 147.349 80.8683 147.403C85.2154 147.547 89.1852 145.642 89.8139 144.601L91.7 141.439L91.7898 145.122C91.8078 145.894 92.1131 146.936 92.4544 147.044C92.5083 147.062 93.6041 147.367 96.7117 143.415L98.3822 144.726C95.598 148.265 93.4963 149.648 91.7719 149.073C90.8557 148.768 90.3348 147.978 90.0294 147.205C87.9278 148.57 84.3172 149.666 80.7785 149.558C78.2278 149.469 76.0543 148.75 74.3657 147.475C71.2761 149.325 68.4918 150.708 68.3661 150.78ZM76.3596 137.397C75.2998 137.397 74.3118 137.667 73.6472 138.224C72.0485 139.535 73.0005 142.032 73.7011 143.397C73.9705 143.918 74.2759 144.385 74.6172 144.78C80.1319 141.259 80.7067 139.625 80.7605 139.194C80.7785 139.104 80.7785 138.978 80.545 138.781C79.3774 137.882 77.7967 137.397 76.3596 137.397Z"
              fill="#4CB970"
            />
            <path
              d="M95.3125 93.0469H67.434V90.4063H95.3125V93.0469Z"
              fill="#2F4F5C"
            />
            <path
              d="M78.5742 122.734H67.4372V120.094H78.5742V122.734Z"
              fill="#2F4F5C"
            />
            <path
              d="M149.762 122.734L82.0413 122.734V120.094L149.762 120.094V122.734Z"
              fill="#2F4F5C"
            />
            <path
              d="M109.899 128.689L100.289 143.077L107.744 137.886C108.121 137.095 108.839 135.838 110.079 134.976C112.001 133.629 113.941 133.09 114.408 133.754C114.875 134.419 113.689 136.054 111.767 137.401C110.528 138.263 109.109 138.515 108.229 138.586L100.774 143.778L117.587 139.754C117.587 139.754 125.958 130.737 123.605 127.342C121.27 123.947 109.899 128.689 109.899 128.689Z"
              fill="#4CB970"
            />
            <path
              d="M78.5742 107.898H67.4372V105.258H78.5742V107.898Z"
              fill="#2F4F5C"
            />
            <path
              opacity="0.1"
              d="M107.293 194.001C166.549 194.001 214.586 186.232 214.586 176.649C214.586 167.066 166.549 159.297 107.293 159.297C48.0366 159.297 0 167.066 0 176.649C0 186.232 48.0366 194.001 107.293 194.001Z"
              fill="#2F4F5C"
            />
            <path
              d="M149.766 93.0469H127.078V90.4063H149.766V93.0469Z"
              fill="#2F4F5C"
            />
            <path
              d="M209.431 63.8281L212.951 68.8757L137.184 125.495L130.07 115.274L209.431 63.8281Z"
              fill="#0B5370"
            />
            <path
              d="M64.7402 6.82812V29.7309H46.3281L64.7402 6.82812Z"
              fill="#DAF4FF"
            />
            <path
              d="M122.055 93.0469H97.7149V90.4063H122.055V93.0469Z"
              fill="#2F4F5C"
            />
            <path
              d="M137.379 125.346L129.727 130.681C127.589 132.172 124.643 131.651 123.153 129.513L121.428 127.034C119.937 124.897 120.458 121.951 122.596 120.46L130.248 115.125L137.379 125.346Z"
              fill="#0B5370"
            />
            <path
              d="M149.762 107.898L82.0413 107.898V105.258L149.762 105.258V107.898Z"
              fill="#2F4F5C"
            />
            <path
              d="M67.4141 45.875H135.134V48.5156H67.4141V45.875ZM138.619 45.875H149.756V48.5156H138.619V45.875ZM67.4141 75.5678H90.1013V78.2083H67.4141V75.5678ZM92.7598 75.5678H149.774V78.2083H92.7598V75.5678ZM67.4141 60.7304H90.1013V63.3709H67.4141V60.7304ZM95.1309 60.7304H119.471V63.3709H95.1309V60.7304ZM121.878 60.7304H149.756V63.3709H121.878V60.7304Z"
              fill="#2F4F5C"
            />
          </svg>
          <h3 class="pb-4 welcome">Welcome to Mieyar!</h3>
          <h3 v-if="source_id == 3" class="pb-2 text">
            By using our platform, you hereby:
            <br />
            <br />
            1. Acknowledge and accept Mieyar's
            <a
              class="link-secondary"
              target="_blank"
              :href="landing_page_url + '/terms_and_conditions'"
              >Terms and Conditions</a
            > AND <a
              class="link-secondary"
              target="_blank"
              :href="landing_page_url + '/privacy_policy'"
              >Privacy Policy</a
            >; and
            <br />
            <br />
            2. Consent to the processing and subsequent disclosure of your data
            to the Emirates Green Building Council for the purposes of their
            Energy & Water Benchmark Study.
          </h3>
          <h3 v-else-if="new_terms==1" class="pb-2 text">
            We have updated our Terms & Conditions and Privacy Policy to better serve you. Please review the changes and accept the new terms to continue using our services.
            <br>
            <br>
            You can read the full updated <a
              class="link-secondary"
              target="_blank"
              :href="landing_page_url + '/terms_and_conditions'"
              >Terms and Conditions</a
            > and <a
              class="link-secondary"
              target="_blank"
              :href="landing_page_url + '/privacy_policy'"
              >Privacy Policy</a
            >.
            <br>
            <br>
            To continue using our platform, please review and accept the new terms.
          </h3>
          <h3 v-else class="pb-2 text">
            By using our platform, you hereby acknowledge and accept Mieyar's
            <a
              class="link-secondary"
              target="_blank"
              :href="landing_page_url + '/terms_and_conditions'"
              >Terms and Conditions</a
            > and <a
              class="link-secondary"
              target="_blank"
              :href="landing_page_url + '/privacy_policy'"
              >Privacy Policy</a
            >.
          </h3>
          <div class="row justify-content-center">
            <div class="col-4">
              <DangerBtn msg="Decline" type="button" @click="decline" />
            </div>
            <div class="col-4">
              <BlueBtn msg="Accept" type="button" @click="accept" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/events/eventBus.js";
import DangerBtn from "@/components/buttons/DangerBtn.vue";
import BlueBtn from "@/components/buttons/BlueBtn.vue";
import CloseIcon from "../icons/CloseIcon.vue";
import { ApiService } from "@/Services/ApiService";

export default {
  name: "TermsAndConditionsModal",
  components: { CloseIcon, DangerBtn, BlueBtn },
  data() {
    return {
      source_id: 0,
      new_terms: 0,
      api: new ApiService(),
      showModal: false,
      landing_page_url: process.env.VUE_APP_LANDINGPAGE_URL,
    };
  },
  async created() {
    eventBus.on("open-terms-and-conditions-modal", async () => {
      const is_admin = localStorage.getItem("admin") ? 1 : 0;
      if (is_admin != 1) {
        const term = await this.api.get(`terms/last`)
        const policy = await this.api.get(`policies/last`)
        const client = await this.api.get("clients/info");
        if(client.data.terms_accepted == 1){
          if(client.data.term_id){
            if(client.data.term_id == term.data.id){
              if(client.data.policy_id){
                if(client.data.policy_id == policy.data.id){
                  // ALL GOOD
                }else{
                  this.new_terms=1
                  this.openModal();
                }
              }else{
                this.source_id = client.data.source_id??1;
                this.openModal();
              }
            }else{
              this.new_terms=1
              this.openModal();
            }
          }else{
            this.source_id = client.data.source_id??1;
            this.openModal();
          }
        }else{
          this.source_id = client.data.source_id??1;
          this.openModal();
        }
      }
    });
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    async accept() {
      eventBus.emit("show-loading", true);
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("terms_accepted", 1);

      const response = await this.api.put("clients/info", formData);

      if ("errors" in response) {
        eventBus.emit("show-loading", false);
        eventBus.emit("open-error-modal", response.message);
      } else {
        eventBus.emit("show-loading", false);
        this.showModal = false;
      }
    },
    decline() {
      this.showModal = false;
      this.$router.push("/home?logout=1");
    },
  },
  unmounted() {
    eventBus.off("open-terms-and-conditions-modal", this.openModal);
    eventBus.off("close-terms-and-conditions-modal", this.decline);
  },
};
</script>
<style scoped>
.welcome {
  color: var(--Prmary-1, #2f4f5c) !important;
  text-align: center !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 140% !important;
  letter-spacing: 1.92px !important;
}
.text {
  color: var(--Prmary-1, #2f4f5c) !important;
  text-align: center !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 98.6% !important; /* 23.664px */
  letter-spacing: 1.2px !important;
}
</style>
