<template>
    <div v-if="isLoading" class="loading-overlay">
        <img src="@/assets/images/Loading.gif" alt="Loading...">
    </div>
</template>
<script>
import { eventBus } from '@/events/eventBus.js';
export default {
    name: 'GifLoading',
    data() {
        return {
            isLoading: false
        }
    },
    created() {
        eventBus.on('show-loading', (show) => {
            this.isLoading = show;
        });
    },
}
</script>
<style scoped>
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.loading-overlay img {
    width: 200px;
    height: 200px;
}</style>