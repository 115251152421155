<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" fill="none">
    <g filter="url(#filter0_d_1286_14976)">
      <rect x="2" y="1" width="48" height="48" rx="10" fill="white"/>
      <rect x="2.5" y="1.5" width="47" height="47" rx="9.5" stroke="#E4E7EC"/>
      <mask id="mask0_1286_14976" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="14" y="13" width="24" height="24">
        <rect x="14" y="13" width="24" height="24" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_1286_14976)">
        <path d="M31 22H33V20H31V22ZM31 26H33V24H31V26ZM31 30H33V28H31V30ZM31 34V32H35V18H26V19.4L24 17.95V16H37V34H31ZM15 34V24L22 19L29 24V34H23V29H21V34H15ZM17 32H19V27H25V32H27V25L22 21.45L17 25V32Z" fill="#2F4F5C"/>
      </g>
    </g>
    <defs>
      <filter id="filter0_d_1286_14976" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1286_14976"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1286_14976" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>
<script>
export default {
    name: 'ShareBuildingIcon'
}
</script>