<template>
    <button :type="type" class="btn btn-theme-primary w-100 p-2" :class="{ rounded: rounded }" ref="buttonRef">
        {{ msg }}
    </button>
</template>

<script>
export default {
    name: "PrimaryBtn2",
    props: {
        type: { type: String, default: 'button' },
        msg: String,
        rounded: Boolean,
    },
    components: {},
    methods: {
        click() {
            this.$refs.buttonRef.click();
        }
    }
};
</script>
<style scoped>
.rounded{
    border-radius: 16px !important;
}
</style>
