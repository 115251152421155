<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 38 38" fill="none">
        <mask id="mask0_666_2593" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="38" height="38">
            <rect x="0.355469" width="37.097" height="37.097" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_666_2593)">
            <path
                d="M13.3398 26.2774L18.9043 20.7128L24.4689 26.2774L26.6329 24.1134L21.0683 18.5489L26.6329 12.9843L24.4689 10.8203L18.9043 16.3849L13.3398 10.8203L11.1758 12.9843L16.7403 18.5489L11.1758 24.1134L13.3398 26.2774ZM18.9043 34.0059C16.7661 34.0059 14.7567 33.6002 12.8761 32.7887C10.9955 31.9772 9.35959 30.8759 7.96846 29.4847C6.57732 28.0936 5.47601 26.4577 4.66451 24.5771C3.85301 22.6965 3.44727 20.6871 3.44727 18.5489C3.44727 16.4106 3.85301 14.4012 4.66451 12.5206C5.47601 10.64 6.57732 9.00412 7.96846 7.61299C9.35959 6.22185 10.9955 5.12054 12.8761 4.30904C14.7567 3.49754 16.7661 3.0918 18.9043 3.0918C21.0426 3.0918 23.052 3.49754 24.9326 4.30904C26.8132 5.12054 28.4491 6.22185 29.8402 7.61299C31.2313 9.00412 32.3327 10.64 33.1441 12.5206C33.9556 14.4012 34.3614 16.4106 34.3614 18.5489C34.3614 20.6871 33.9556 22.6965 33.1441 24.5771C32.3327 26.4577 31.2313 28.0936 29.8402 29.4847C28.4491 30.8759 26.8132 31.9772 24.9326 32.7887C23.052 33.6002 21.0426 34.0059 18.9043 34.0059ZM18.9043 30.9145C22.3564 30.9145 25.2804 29.7166 27.6762 27.3207C30.0721 24.9249 31.27 22.0009 31.27 18.5489C31.27 15.0968 30.0721 12.1728 27.6762 9.77698C25.2804 7.38113 22.3564 6.18321 18.9043 6.18321C15.4523 6.18321 12.5283 7.38113 10.1324 9.77698C7.7366 12.1728 6.53868 15.0968 6.53868 18.5489C6.53868 22.0009 7.7366 24.9249 10.1324 27.3207C12.5283 29.7166 15.4523 30.9145 18.9043 30.9145Z"
                fill="#2F4F5C" />
        </g>
    </svg>
</template>
<script>
export default {
    name: 'CloseIcon',
    props: {
        size: {
            type: Number,
            default: 38 // Default size, you can change this to your preferred default size
        }
    }
}
</script>