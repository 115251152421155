<template>
  <div v-if="showModal" class="modal show" style="display: block; background-color: rgba(0,0,0,0.8);" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center position-relative">
          <button type="button" class="btn-close position-absolute top-0 end-0 p-3" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
          <svg class="rounded-circle text-center bg-secondary p-2 my-2" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
            <path d="M22.0003 40.3332C32.0837 40.3332 40.3337 32.0832 40.3337 21.9998C40.3337 11.9165 32.0837 3.6665 22.0003 3.6665C11.917 3.6665 3.66699 11.9165 3.66699 21.9998C3.66699 32.0832 11.917 40.3332 22.0003 40.3332Z" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M14.208 21.9999L19.3963 27.1882L29.7913 16.8115" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <h3 class="pt-3 pb-4">{{ textForModal }}</h3>
          <div class="row justify-content-center">
            <div class="col-8">
              <PrimaryBtn2 msg="Continue" type="button" @click="closeModal"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/events/eventBus.js';
import PrimaryBtn2 from '@/components/buttons/PrimaryBtn2.vue';

export default {
  name: 'SuccessModal',
  components: { PrimaryBtn2 },
  props: { hasButton: Boolean,  },
  data() {
    return {
      showModal: false,
      textForModal: '',
    };
  },
  created() {
    eventBus.on('open-success-modal', (msg) => {
      this.openModal(msg)
    });
  },
  methods: {
    openModal(msg) {
      this.textForModal = msg;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      if(localStorage.getItem('reload') == 1){
        localStorage.removeItem('reload')
        window.location.reload();
      }
      if(localStorage.getItem('reroute') != null){
        const reroute = localStorage.getItem('reroute')
        localStorage.removeItem('reroute')
        this.$router.push(reroute);
      }
    },
  },
  unmounted() {
    eventBus.off('open-success-modal', this.openModal);
    eventBus.off('close-success-modal', this.closeModal);
  },
};
</script>
