<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :fill="color">
    <mask id="mask0_212_1135" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" :fill="color" />
    </mask>
    <g mask="url(#mask0_212_1135)">
      <path
        d="M11.2264 9V3H20.2075V9H11.2264ZM0 13V3H8.98113V13H0ZM11.2264 21V11H20.2075V21H11.2264ZM0 21V15H8.98113V21H0ZM2.24528 11H6.73585V5H2.24528V11ZM13.4717 19H17.9623V13H13.4717V19ZM13.4717 7H17.9623V5H13.4717V7ZM2.24528 19H6.73585V17H2.24528V19Z"
        :fill="color" />
    </g>
  </svg>
</template>
<script>
export default {
  name: 'DashboardIcon',
  props: { color: { type: String, default: '#aaa' } }
}
</script>