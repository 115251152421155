<template>
    <svg xmlns="http://www.w3.org/2000/svg" style="display: inline;" width="12" height="20" viewBox="0 0 12 20"
        fill="none" class="breadcrumb-separator">
        <path d="M10 20L0 10L10 0L11.775 1.775L3.55 10L11.775 18.225L10 20Z" fill="#2F4F5C" />
    </svg>
</template>
<script>
export default {
    name: 'ArowIcon'
}
</script>
<style scoped>
.breadcrumb-separator {
    margin: 0 5px;
    /* Adjust margin as needed */
}
</style>