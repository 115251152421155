<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" :fill="color">
    <path
      d="M2.91479 10.4208C3.34925 9.07709 4.06495 7.84368 5.0093 6.7984C5.169 6.62164 5.41953 6.55864 5.64384 6.63884L7.90065 7.44579C8.5125 7.66443 9.18575 7.34568 9.4044 6.73383C9.4259 6.67367 9.44247 6.61185 9.45391 6.5491L9.88386 4.18909C9.92661 3.95444 10.1068 3.76883 10.3401 3.71917C11.0227 3.57388 11.7225 3.5 12.4316 3.5C13.1402 3.5 13.8396 3.57379 14.5218 3.71891C14.755 3.76852 14.9352 3.95401 14.9781 4.18857L15.4092 6.549C15.5258 7.1882 16.1385 7.61188 16.7777 7.49531C16.8405 7.48385 16.9023 7.46728 16.9624 7.44582L19.2193 6.63884C19.4436 6.55864 19.6941 6.62164 19.8538 6.7984C20.7982 7.84368 21.5139 9.07709 21.9484 10.4208C22.0217 10.6475 21.951 10.896 21.7694 11.0502L19.9401 12.6032C19.4449 13.0238 19.3843 13.7662 19.8048 14.2615C19.8462 14.3102 19.8914 14.3554 19.9401 14.3968L21.7694 15.9498C21.951 16.104 22.0217 16.3525 21.9484 16.5792C21.5139 17.9229 20.7982 19.1563 19.8538 20.2016C19.6941 20.3784 19.4436 20.4414 19.2193 20.3612L16.9625 19.5542C16.3506 19.3356 15.6774 19.6543 15.4587 20.2662C15.4372 20.3263 15.4207 20.3882 15.4092 20.4512L14.9781 22.8114C14.9352 23.046 14.755 23.2315 14.5218 23.2811C13.8396 23.4262 13.1402 23.5 12.4316 23.5C11.7225 23.5 11.0227 23.4261 10.3401 23.2808C10.1068 23.2312 9.92661 23.0456 9.88386 22.8109L9.45393 20.451C9.33736 19.8118 8.72468 19.3881 8.08548 19.5047C8.02262 19.5162 7.96081 19.5327 7.90074 19.5542L5.64384 20.3612C5.41953 20.4414 5.169 20.3784 5.0093 20.2016C4.06495 19.1563 3.34925 17.9229 2.91479 16.5792C2.84149 16.3525 2.91217 16.104 3.09378 15.9498L4.923 14.3968C5.41829 13.9762 5.47886 13.2338 5.05831 12.7385C5.01696 12.6898 4.9717 12.6446 4.92303 12.6032L3.09378 11.0502C2.91217 10.896 2.84149 10.6475 2.91479 10.4208ZM10.0786 13.5C10.0786 14.7995 11.1321 15.8529 12.4316 15.8529C13.7311 15.8529 14.7845 14.7995 14.7845 13.5C14.7845 12.2005 13.7311 11.1471 12.4316 11.1471C11.1321 11.1471 10.0786 12.2005 10.0786 13.5Z"
      :fill="color" />
  </svg>
</template>

<script>
export default {
  name: 'CogIcon',
  props: { color: { type: String, default: '#aaa' } }
}
</script>