<template>
    <button :type="type" class="btn btn-theme-primary2 w-100 p-2" ref="buttonRef">
        {{ msg }}
    </button>
</template>

<script>
export default {
    name: "BlueBtn",
    props: {
        type: { type: String, default: 'button' },
        msg: String,
    },
    components: {},
    methods: {
        click() {
            this.$refs.buttonRef.click();
        }
    }
};
</script>
