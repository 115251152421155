import "bootstrap/dist/css/bootstrap.css";
import "@/assets/styles/main.scss";
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import VueSelect from "vue-select";
import VOtpInput from "vue3-otp-input";
import "vue-select/dist/vue-select.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
// import "bootstrap/dist/js/bootstrap.js";
import * as bootstrap from "bootstrap";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import VueGtag from "vue-gtag";

const app = createApp(App)
  .component("v-otp-input", VOtpInput)
  .component("v-select", VueSelect)
  .component("VueDatePicker", VueDatePicker)
  .component("QuillEditor", QuillEditor)
  .use(router)
  .use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG },
    },
    router
  )
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    },
  })
  .directive("tooltip", {
    mounted(el, binding) {
      let titleValue = binding.value;
      let customClass = binding.value.customClass || "";
      let placement = binding.value.placement || "right";
      if (typeof titleValue === "object" && titleValue.content) {
        titleValue = titleValue.content; // Assuming the content is the string title
      }
      new bootstrap.Tooltip(el, {
        title: titleValue,
        trigger: "hover",
        placement: placement,
        customClass: customClass,
      });

      // Apply the custom class
      el.addEventListener("shown.bs.tooltip", () => {
        const tooltipElement = document.querySelector(".tooltip-inner");
        if (tooltipElement && customClass) {
          tooltipElement.classList.add(customClass);
        }
      });
    },
  });
app.mount("#app");
