<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" :fill="color">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M9.69795 0.799227C10.0605 0.488302 10.5223 0.317383 11 0.317383C11.4776 0.317383 11.9394 0.488302 12.302 0.799227L18.638 6.22923L21.125 8.21923C21.2275 8.3013 21.3129 8.40278 21.3763 8.51785C21.4396 8.63293 21.4797 8.75936 21.4941 8.88992C21.5086 9.02048 21.4973 9.15261 21.4607 9.27878C21.4241 9.40494 21.363 9.52267 21.281 9.62523C21.1989 9.72779 21.0974 9.81318 20.9823 9.87653C20.8672 9.93987 20.7408 9.97993 20.6103 9.99442C20.4797 10.0089 20.3476 9.99753 20.2214 9.96095C20.0952 9.92438 19.9775 9.8633 19.875 9.78123L19 9.08123V17.0002C19 17.5307 18.7892 18.0394 18.4142 18.4144C18.0391 18.7895 17.5304 19.0002 17 19.0002H13V14.0002C13 13.4698 12.7892 12.9611 12.4142 12.586C12.0391 12.2109 11.5304 12.0002 11 12.0002C10.4695 12.0002 9.96081 12.2109 9.58574 12.586C9.21067 12.9611 8.99995 13.4698 8.99995 14.0002V19.0002H4.99995C4.46952 19.0002 3.96081 18.7895 3.58574 18.4144C3.21067 18.0394 2.99995 17.5307 2.99995 17.0002V9.08023L2.12495 9.78023C2.02309 9.86721 1.90478 9.93284 1.77707 9.97322C1.64935 10.0136 1.51482 10.0279 1.38146 10.0153C1.24811 10.0027 1.11865 9.96344 1.00076 9.89983C0.882872 9.83623 0.778965 9.74959 0.695205 9.64506C0.611446 9.54053 0.549541 9.42023 0.513165 9.29132C0.476789 9.1624 0.466684 9.02749 0.48345 8.89459C0.500215 8.76169 0.543509 8.63352 0.610762 8.51768C0.678016 8.40183 0.767857 8.30068 0.874953 8.22023L3.36195 6.23023L9.69795 0.799227Z"
      :fill="color" />
  </svg>
</template>
<script>
export default {
  name: 'HomeIcon',
  props: { color: { type: String, default: '#aaa' } }
}
</script>