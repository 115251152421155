<template>
  <div>
    <div class="input-group" ref="inputGroup">
      <span class="input-group-text bg-white border-0">
        <SearchIcon />
      </span>
      <input
        class="form-control border-0 px-0"
        placeholder="Search any keywords"
        style="background-color: white"
        v-model="formData.search"
        name="search"
        type="text"
        id="search"
        @keyup="fetchSearchResults"
        required
      />
    </div>
    <ul
      class="dropdown-menu show"
      v-if="searchResults.length > 0"
      :style="{ width: dropdownWidth }"
    >
      <li v-for="result in searchResults" :key="result.id" class="dropdown-item text" @click="goToProperty(result.id)">
        <img
          :src="result.image"
          alt="result.name"
          class="me-2 rounded"
          style="width: 30px; height: 30px"
        />
        {{ result.name }}
      </li>
    </ul>
    <ul
      class="dropdown-menu show"
       v-else-if="formData.search.length > 0 && searchResults.length == 0"
      :style="{ width: dropdownWidth }"
    >
      <li class="dropdown-item text-danger">
        <NoResultsIcon />No properties found
      </li>
    </ul>
  </div>
</template>


<script>
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NoResultsIcon from "@/components/icons/NoResultsIcon.vue";
import { ApiService } from "@/Services/ApiService";

export default {
  name: "SearchForm",
  data() {
    return {
      formData: {
        search: "",
      },
      searchResults: [],
      dropdownWidth: "0px",
    };
  },
  methods: {
    async fetchSearchResults() {
      if (this.formData.search.length > 0) {
        const apiService = new ApiService();
        this.searchResults = await apiService.get("clients/properties", {
          search: this.formData.search,
        });
        this.searchResults = this.searchResults.data;
        this.updateDropdownWidth();
      } else {
        this.searchResults = [];
      }
    },
    goToProperty(id) {
      window.location.href = `/properties/${id}`;
      this.formData.search = "";
      this.searchResults = [];
    },
    updateDropdownWidth() {
      const inputGroup = this.$refs.inputGroup;
      if (inputGroup) {
        this.dropdownWidth = `${inputGroup.offsetWidth}px`;
      }
    },
  },
  mounted() {
    this.updateDropdownWidth();
    window.addEventListener("resize", this.updateDropdownWidth);
  },
  components: { SearchIcon, NoResultsIcon },
};
</script>

<style scoped>
.dropdown-menu {
  display: block;
  max-height: 200px;
  overflow-y: auto;
}
.text {
  color: var(--Prmary-1, #2f4f5c);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
}
</style>
